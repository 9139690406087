.drawerContainer {
  height: 100vh;
  width: 415px;
  background-color: #e6e1eb;
  transition: all 0.3s ease-in-out;
  transform: translate(305px); /* Move container off-screen to the right */
  position: fixed;
  top: 50px;
  right: -114px; /* Position container flush against the right edge of the viewport */
  padding: 15px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0px 10px -3px;
  overflow: hidden;
  z-index: 1000;
  overflow-y: auto;
}
.drawerContainerOpen {
  transform: translate(20px);
  right: 25px;
}

.drawerTab {
  position: fixed;
  bottom: 0;
  right: -15px;
  width: 65px;
  height: 50px;
  transition: all 0.3s ease-in-out;
  transform: translate(0px);
  background-color: #5c4174;
  border: none;
  cursor: pointer;
  padding: 10px 10px 0px 10px;
  border-radius: 8px 0 0 8px;
  box-shadow: -2px 0px 10px -3px;
}
.drawerTabOpen {
  transform: translate(-265px);
  right: 145px;
}
.hotKeyInput {
  width: 30px;
  margin: 5px 0;
  border: 1px solid #5c4174;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
}
.hotKeyLabel {
  font-size: 16px;
}
.keyMapText {
  float: right;
  margin-top: -32px;
}
.drawerInnerTab {
  font-weight: bold;
  font-size: 1.2em;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 45%;
  padding-left: 5px;
  float: left;
  text-align: center;
}

.drawerInnerTabInactive {
  font-weight: bold;
  font-size: 1.2em;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 45%;
  padding-left: 5px;
  float: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #795298;
  color: rgba(255, 255, 255, 0.3);
  text-align: center;
}
.drawerInnerTabInactive:hover {
  color: #fff;
}
.drawerInnerTab:hover {
  background-color: #f0f0f0;
}
.drawerInnerTab:active {
  background-color: #e6e1eb;
}
.drawerInnerTabSpacerRight {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 52px;
  height: 30px;
  float: left;
  margin-right: -15px;
}
.drawerInnerTabSpacerLeft {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  width: 16px;
  margin-left: -15px;
  height: 30px;
  float: left;
}
.drawerContent {
  padding: 10px;
  margin-top: 20px;
}
.drawerContentHidden {
  display: none;
}
.ctrlDisplay {
  border: 1px solid #795298;
  border-radius: 3px;
  padding: 2px;
}
.drawerButton {
  background-color: #795298;
  color: white;
  border: 1px solid #34005f;
  border-radius: 3px;
  padding: 5px;
  margin: 25px 5px 5px 5px;
  cursor: pointer;
  width: 100%;
  text-transform: uppercase;
}
.tabContentTitle {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}
div[data-ag-charts] {
  width: 114%;
  margin-left: -17px;
}